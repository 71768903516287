import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { Container, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { faUserPlus, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { startRegister } from '../../actions/register'
import CardHeader from '../CardHeader'
import Input from '../Input'
import InputConfirm from '../InputConfirm'
import Button from '../Button'

export class Register extends React.Component {

    constructor(props) {
        super(props)

        // set the initial state
        this.state = {
            username: '',
            email: '',
            password: '',
            password2: '',
            passwordsMatch: false,
            captcha: null,
            registered: false
        }

        // bind this to the functions
        this.handleChange = this.handleChange.bind(this)
        this.handlePasswordMatchChange = this.handlePasswordMatchChange.bind(this)
        this.handleCaptchaChange = this.handleCaptchaChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    // handle component updates
    componentDidUpdate(prevProps) {
        // if register stopped loading
        if (prevProps.loading && !this.props.loading) {
            // if there are errors, toast them (only those without param property)
            if (this.props.errors) {
                this.props.errors.filter(e => !e.param).forEach(e => toast.error(e.msg))
            // successful registration
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    registered: true
                }))
            }
        }
    }

    // handles the form value changes
    handleChange(e) {
        const name = e.target.name
        const value = e.target.value
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    // handles the captcha token changes
    handleCaptchaChange(token) {
        this.setState((prevState) => ({
            ...prevState,
            captcha: token
        }))
    }

    handlePasswordMatchChange(matches) {
        this.setState((prevState) => ({
            ...prevState,
            passwordsMatch: matches
        }))
    }

    handleSubmit(e) {
        // prevent the form submission
        e.preventDefault()

        // start registration
        this.props.startRegister({
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
            captcha: this.state.captcha
        })
    }

    hasValue(str) {
        return !!str && str.length > 0
    }

    canSubmit() {
        return this.hasValue(this.state.username) && 
            this.hasValue(this.state.email) && 
            this.hasValue(this.state.password) && 
            this.hasValue(this.state.password2) &&
            this.state.passwordsMatch &&
            !!this.state.captcha &&
            !this.props.loading
    }

    render() {
        const headerText = this.state.registered ? 'Email Confirmation' : 'Create your account'
        const headerIcon = this.state.registered ? faEnvelope : faUserPlus
        return (
            <Container className="container-page content-center">
                <Card className="card-md card-responsive mb-3">
                    <CardHeader icon={ headerIcon }>
                        { headerText }
                    </CardHeader>
                    <Card.Body>
                        {this.state.registered ? (
                            <Fragment>
                                <Card.Text>You've created an account and all you need to do is confirm your email address.</Card.Text>
                                <Card.Text>Check your inbox at <b>{this.state.email}</b> and click the email confirmation link!</Card.Text>
                            </Fragment>
                        ) : (
                            <form onSubmit={this.handleSubmit}>
                                <Input
                                    type="text"
                                    placeholder="Username"
                                    required
                                    autoComplete="username"
                                    name="username"
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                    disabled={this.props.loading}
                                    error={this.props.errors && this.props.errors.find(e => e.param === 'username')}
                                />
                                <Input
                                    type="email"
                                    placeholder="Email"
                                    required
                                    autoComplete="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    disabled={this.props.loading}
                                    error={this.props.errors && this.props.errors.find(e => e.param === 'email')}
                                />
                                <InputConfirm
                                    type="password"
                                    onChange={this.handleChange}
                                    onMatch={this.handlePasswordMatchChange}
                                    matchError="Passwords do not match"
                                    required
                                    name="password"
                                    otherName="password2"
                                    placeholder="Password"
                                    otherPlaceholder="Confirm Password"
                                    autoComplete="new-password"
                                    disabled={this.props.loading}
                                    error={this.props.errors && this.props.errors.find(e => e.param === 'password')}
                                />
                                <div className="d-flex justify-content-center mb-2">
                                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={this.handleCaptchaChange} size="compact" />
                                    <p className="recaptcha__feedback">{this.props.errors && (this.props.errors.find(e => e.param === 'captcha') || {}).msg}</p>
                                </div>
                                <Button type="submit" block disabled={ !this.canSubmit() } loading={this.props.loading}>
                                    Create Account
                                </Button>
                            </form>
                        )}
                    </Card.Body>
                </Card>
                {!this.state.registered &&
                    <Link to="/login" className="mb-3"><small>Already have an account?</small></Link>
                }
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.register.register.loading,
    errors: state.register.register.errors
})

Register.propTypes = {
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.array,
    startRegister: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { startRegister })(Register)