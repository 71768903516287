import React from 'react'
import PropTypes from 'prop-types'
import { Button as BootstrapButton } from 'react-bootstrap'
import Loading from './Loading'

const Button = ({ variant="primary", type, block, disabled, loading, loadingText="Loading", iconType, iconColor="white", iconSize, children, ...props }) => (
    <BootstrapButton
        {...props}
        variant={variant}
        type={type}
        block={block}
        disabled={disabled || loading}>
        {loading ? (
            <Loading
                iconType={iconType}
                iconColor={iconColor}
                iconSize={iconSize}>
                {loadingText}
            </Loading>
        ) : (children)}
    </BootstrapButton>
)

Button.propTypes = {
    variant: PropTypes.string,
    type: PropTypes.string,
    block: PropTypes.bool,
    disabled: PropTypes.bool,
    iconType: PropTypes.string,
    iconColor: PropTypes.string,
    iconSize: PropTypes.number,
    loading: PropTypes.bool,
    loadingText: PropTypes.node
}

export default Button