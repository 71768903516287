import React from 'react'
import { Container, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFrown } from '@fortawesome/free-regular-svg-icons'

class NotFound extends React.Component {
    render() {
        return (
            <Container className="d-flex justify-content-center">
                <Card className="card-sm text-center mb-5 mt-5">
                    <Card.Header as="h3" className="font-weight-bold header-danger">404 Error</Card.Header>
                    <Card.Body>
                        <Card.Title>Resource Not Found</Card.Title>
                        <Card.Text style={{fontSize:'4rem'}}>
                            <FontAwesomeIcon icon={faFrown} />
                        </Card.Text>
                        <Card.Text>It looks like you've tried to access a page that does not exist.</Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

export default NotFound