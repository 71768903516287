import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Container, Card } from 'react-bootstrap'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { startCheck, startUpdate } from '../../actions/forgot'
import InputConfirm from '../InputConfirm'
import CardHeader from '../CardHeader'
import Loading from '../Loading'
import Button from '../Button'
import Hint from '../Hint'

export class ForgotUpdate extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            password: '',
            password2: '',
            passwordsMatch: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.canSubmit = this.canSubmit.bind(this)
        this.handlePasswordMatch = this.handlePasswordMatch.bind(this)
    }

    componentDidMount() {
        // start checking the URL token
        this.props.startCheck(this.props.match.params.token)
    }

    componentDidUpdate(prevProps) {
        // if we've stopped checking the token, change the state
        if (prevProps.checkLoading && !this.props.checkLoading) {
            if (this.props.checkError) {
                // navigate to login and toast an error
                this.props.history.push('/login')
                toast.error('Password Reset: ' + this.props.checkError.msg)
            }
        }

        // if password reset complete
        if (prevProps.updateLoading && !this.props.updateLoading) {
            // toast any non-password error
            if (this.props.updateErrors) {
                this.props.updateErrors.filter(e => !e.param || e.param !== 'password').forEach(e => toast.error(e.msg))
            // redirect to login and post success toast
            } else {
                this.props.history.push('/login')
                toast.success('Your password has been reset, please sign in!')
            }
        }
    }

    // handles the form value changes
    handleChange(e) {
        const name = e.target.name
        const value = e.target.value
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    handleSubmit(e) {
        // prevent the form submission
        e.preventDefault()

        // start registration
        this.props.startUpdate({
            token: this.props.match.params.token,
            password: this.state.password
        })
    }

    handlePasswordMatch(matches) {
        this.setState((prevState) => ({
            ...prevState,
            passwordsMatch: matches
        }))
    }

    hasValue(str) {
        return !!str && str.length > 0
    }

    canSubmit() {
        return this.hasValue(this.state.password) &&
            this.hasValue(this.state.password2) &&
            this.state.passwordsMatch &&
            !this.props.updateLoading
    }

    render() {
        return (
            <Container className="container-page content-center">
                <Card className="card-md card-responsive mb-3">
                    <CardHeader icon={faKey}>
                        Password Reset
                    </CardHeader>
                    <Card.Body>
                        {this.props.checkLoading ? (
                            <div className="text-center">
                                <Loading>Checking token...</Loading>
                            </div>
                        ) : (
                            <Fragment>
                                <Hint className="mt-1 mb-4">Please enter your new password</Hint>
                                <form onSubmit={this.handleSubmit}>
                                    <InputConfirm
                                        type="password"
                                        onChange={this.handleChange}
                                        onMatch={this.handlePasswordMatch}
                                        matchError="Passwords do not match"
                                        required
                                        name="password"
                                        otherName="password2"
                                        label="New Password"
                                        otherLabel="Confirm New Password"
                                        autoComplete="new-password"
                                        disabled={this.props.updateLoading}
                                        error={this.props.updateErrors && this.props.updateErrors.find(e => e.param === 'password')}
                                    />
                                    <Button type="submit" block disabled={ !this.canSubmit() } loading={this.props.updateLoading}>
                                        Reset password
                                    </Button>
                                </form>
                            </Fragment>
                        )}
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

ForgotUpdate.propTypes = {
    checkLoading: PropTypes.bool.isRequired,
    checkError: PropTypes.object,
    updateLoading: PropTypes.bool.isRequired,
    updateErrors: PropTypes.array,
    startCheck: PropTypes.func.isRequired,
    startUpdate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    checkLoading: state.forgot.check.loading,
    checkError: state.forgot.check.error,
    updateLoading: state.forgot.update.loading,
    updateErrors: state.forgot.update.errors
})

export default connect(mapStateToProps, { startCheck, startUpdate })(ForgotUpdate)