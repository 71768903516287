import axios from 'axios'
import {
    // registration
    REGISTER_SUBMITTED,
    REGISTER_SUCCESS,
    REGISTER_FAIL,

    // email confirmation
    EMAIL_CONFIRM_SUBMITTED,
    EMAIL_CONFIRM_SUCCESS,
    EMAIL_CONFIRM_FAIL
} from './types'

export const startRegister = ({ username, email, password, captcha }) => async dispatch => {
    try {
        // change the registration state to submitting
        dispatch({
            type: REGISTER_SUBMITTED
        })

        // await the reponse from the server
        await axios.post(
            '/api/auth/register',
            JSON.stringify({ username, email, password, captcha }),
            { headers: { 'Content-Type': 'application/json' }}
        )

        // registraction successful
        dispatch({
            type: REGISTER_SUCCESS
        })
    } catch (error) {
        dispatch({
            type: REGISTER_FAIL,
            payload: [ ...(error.response ? error.response.data.errors : [{ msg: error.message }]) ]
        })
    }
}

export const startConfirmEmail = (token) => async dispatch => {
    try {
        // change the confirmation state to submitting
        dispatch({
            type: EMAIL_CONFIRM_SUBMITTED
        })

        // post the confirmation token to the API
        await axios.post(
            '/api/auth/confirm',
            JSON.stringify({ token }),
            { headers: { 'Content-Type': 'application/json' }}
        )

        // email confirmed
        dispatch({
            type: EMAIL_CONFIRM_SUCCESS
        })
    } catch (error) {
        dispatch({
            type: EMAIL_CONFIRM_FAIL,
            payload: [ ...(error.response ? error.response.data.errors : [{ msg: error.message }]) ]
        })
    }
}