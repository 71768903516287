import axios from 'axios'
import unauthorised from '../utils/unauthorised'
import { 
    UPLOAD_SUBMITTED,
    UPLOAD_SUCCESS,
    UPLOAD_FAIL,
    USERNAME_SUBMITTED,
    USERNAME_SUCCESS,
    USERNAME_FAIL,
    EMAIL_SUBMITTED,
    EMAIL_SUCCESS,
    EMAIL_FAIL,
    EMAIL_UPDATE_SUBMITTED,
    EMAIL_UPDATE_SUCCESS,
    EMAIL_UPDATE_FAIL,
    PASSWORD_SUBMITTED,
    PASSWORD_SUCCESS,
    PASSWORD_FAIL,
    DELETE_SUBMITTED,
    DELETE_SUCCESS,
    DELETE_FAIL,
    LOGOUT_SUCCESS
} from './types'

export const startUpload = (file) => async dispatch => {
    try {

        dispatch({
            type: UPLOAD_SUBMITTED
        })

        const formData = new FormData()
        formData.append("image", file, file.name)

        await axios.post('/api/account/upload', formData)

        dispatch({
            type: UPLOAD_SUCCESS
        })

    } catch (error) {
        if (unauthorised(error)) { 
            return dispatch({ type: LOGOUT_SUCCESS })
        }

        dispatch({
            type: UPLOAD_FAIL,
            payload: { ...(error.response ? error.response.data.error : { msg: error.message }) }
        })
    }
}

export const startUsernameChange = (username) => async dispatch => {
    try {
        // change the state to submitting
        dispatch({
            type: USERNAME_SUBMITTED
        })

        // request the change to the API
        const res = await axios.post(
            '/api/account/username',
            JSON.stringify({ username }),
            { headers: { 'Content-Type': 'application/json' }}
        )

        // successfully changed username, pass returned username to the state
        dispatch({
            type: USERNAME_SUCCESS,
            payload: res.data.username
        })

    } catch (error) {
        if (unauthorised(error)) { 
            return dispatch({ type: LOGOUT_SUCCESS })
        }

        dispatch({
            type: USERNAME_FAIL,
            payload: [ ...(error.response ? error.response.data.errors : [ { msg: error.message } ]) ]
        })
    }
}

export const startEmailChange = (email, password) => async dispatch => {
    try {
        // change the state to submitting
        dispatch({
            type: EMAIL_SUBMITTED
        })

        // request the change to the API
        await axios.post(
            '/api/account/email',
            JSON.stringify({ email, password }),
            { headers: { 'Content-Type': 'application/json' }}
        )

        // email change successfully submitted
        dispatch({
            type: EMAIL_SUCCESS
        })

    } catch (error) {
        if (unauthorised(error)) { 
            return dispatch({ type: LOGOUT_SUCCESS })
        }

        dispatch({
            type: EMAIL_FAIL,
            payload: [ ...(error.response ? error.response.data.errors : [ { msg: error.message } ]) ]
        })
    }
}

export const startPasswordChange = (password, newPassword) => async dispatch => {
    try {
        // change the state to submitting
        dispatch({
            type: PASSWORD_SUBMITTED
        })

        // request the change to the API
        await axios.post(
            '/api/account/password',
            JSON.stringify({ password, newPassword }),
            { headers: { 'Content-Type': 'application/json' }}
        )

        // successfully changed password
        dispatch({
            type: PASSWORD_SUCCESS
        })

    } catch (error) {
        if (unauthorised(error)) { 
            return dispatch({ type: LOGOUT_SUCCESS })
        }
        
        dispatch({
            type: PASSWORD_FAIL,
            payload: [ ...(error.response ? error.response.data.errors : [ { msg: error.message } ]) ]
        })
    }
}

export const startEmailUpdate = (token) => async dispatch => {
    try {
        // change the state to submitting
        dispatch({
            type: EMAIL_UPDATE_SUBMITTED
        })

        // request the email confirmation from the API
        const res = await axios.post(
            '/api/account/email/confirm',
            JSON.stringify({ token }),
            { headers: { 'Content-Type': 'application/json' } }
        )

        // successfully updated email
        dispatch({
            type: EMAIL_UPDATE_SUCCESS,
            payload: res.data.email
        })
    } catch (error) {
        if (unauthorised(error)) { 
            return dispatch({ type: LOGOUT_SUCCESS })
        }

        dispatch({
            type: EMAIL_UPDATE_FAIL,
            payload: [...(error.response ? error.response.data.errors : [{ msg: error.message }])]
        })
    }
}

export const startAccountDelete = (password) => async dispatch => {
    try {
        // change the state to submitting
        dispatch({
            type: DELETE_SUBMITTED
        })

        // request the email confirmation from the API
        await axios.post(
            '/api/account/delete',
            JSON.stringify({ password }),
            { headers: { 'Content-Type': 'application/json' } }
        )

        // successfully deleted account
        dispatch({
            type: DELETE_SUCCESS
        })
    } catch (error) {
        
        if (unauthorised(error)) { 
            return dispatch({ type: LOGOUT_SUCCESS })
        }

        dispatch({
            type: DELETE_FAIL,
            payload: [...(error.response ? error.response.data.errors : [{ msg: error.message }])]
        })
    }
}