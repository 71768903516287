import axios from 'axios'

export const logError = async function (stack, componentStack) {
    try {
        await axios.post(
            '/api/error/log',
            JSON.stringify({ stack, componentStack }),
            { headers: { 'Content-Type': 'application/json' }}
        )
    } catch (error) {
        console.error(error)
    }
}