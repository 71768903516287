import Loader from 'react-loader-spinner'
import { colorPrimary } from '../styles/_bootstrap.module.scss'

const Loading = ({children, className, iconType="Grid", iconSize=15, iconColor=colorPrimary}) => (
    <div className={`loading-text ${className}`}>
        <Loader type={iconType} color={iconColor} width={iconSize} height={iconSize} className="loading-text-icon" />
        {children}
    </div>
)

export default Loading