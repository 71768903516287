import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { Card, ResponsiveEmbed, Carousel } from 'react-bootstrap'

class AppCard extends React.Component {
    render() {
        return (
            <Card className="app-card">
                <Card.Header className="bg-dark text-light">
                    <Card.Title className="text-center mb-0 font-weight-bold">
                        {this.props.titleIcon && 
                            <FontAwesomeIcon icon={this.props.titleIcon} className="mr-1" />
                        }
                        {this.props.title}
                    </Card.Title>
                </Card.Header>
                <ResponsiveEmbed aspectRatio="16by9">
                    <div className="embed-responsive-item" style={{ display:'flex', alignItems:'center', overflowY:'visible' }}>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="images/meet-caller/meetings.jpg"
                                    alt="First slide" 
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="images/meet-caller/call.jpg"
                                    alt="First slide" 
                                />
                            </Carousel.Item>
                        </Carousel>
                        <div className="shadow" />
                    </div>
                </ResponsiveEmbed>
                <Card.Body>
                    <Card.Text>{this.props.text}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    {this.props.href && 
                        <NavLink to={this.props.href} className="btn btn-primary">
                            <FontAwesomeIcon icon={faEye} /> View
                        </NavLink>
                    }
                    {this.props.download && 
                        <NavLink to={this.props.download} className="btn btn-secondary">
                            <FontAwesomeIcon icon={faDownload} /> Download
                        </NavLink>
                    }
                    {this.props.github &&
                        <NavLink to={this.props.github} target="_blank" className="btn btn-github">
                            <FontAwesomeIcon icon={faGithub} /> Github
                        </NavLink>
                    }
                </Card.Footer>
            </Card>
        )
    }
}

AppCard.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
}

export default AppCard

/*
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'

const AppCard = ({ imageSrc, title, text, url, githubRepo }) => (
    <div className="col-12 col-md-6 col-lg-4 mb-3 d-flex flex-grow">
        <div className="card flex-grow flex-fill">
            <Link to={url} style={{background: 'url(\'' + imageSrc + '\') no-repeat center center/cover #CCC', height: '13rem'}}></Link>
            <div className="card-body d-flex flex-column">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{text}</p>
                <div className="d-flex flex-grow-1 align-items-end">
                    <div className="d-flex flex-grow-1 align-items-center justify-content-between">
                        <Link to={url} className="btn btn-primary"><FontAwesomeIcon icon={faPlayCircle} /> Launch</Link>
                        {githubRepo && <a href={githubRepo} target="_blank" className="btn btn-info float-right" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} /> Github</a>}
                    </div>
                </div>
            </div>
        </div>
    </div>
)

AppCard.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    githubRepo: PropTypes.string
}

export default AppCard
*/