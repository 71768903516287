import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'

export class AccountSetting extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            active: false
        }

        this.handleClick = this.handleClick.bind(this)
        this.isActive = this.isActive.bind(this)
    }

    isActive() {
        return this.props.activeSetting === this
    }

    handleClick() {
        // ignore clicks if already active
        if (this.isActive()) {
            return
        }

        // fire the passed onClick function
        this.props.onClick(this)
    }

    render() {
        return (
            <div className="account-setting">
                {this.isActive() ? (
                    <form className="setting-open" onSubmit={ this.props.onSubmit }>
                        <h5 className="account-setting__heading">{this.props.heading}</h5>
                        <hr/>
                        {this.props.children}
                        <hr className="mt-0" />
                        <div className="flex-row">
                            {!this.props.isContainer &&
                                <Button type="submit" disabled={ this.props.disabled } loading={this.props.loading} loadingText="Saving" className="mr-3">
                                    Save Changes
                                </Button>
                            }
                            <Button onClick={this.props.onCancel} type="reset" variant="secondary" disabled={ this.props.loading }>Cancel</Button>
                        </div>
                    </form>
                ) : (
                    <div className="account-setting">
                        <div className="setting-preview" onClick={this.handleClick}>
                            <div className="setting-value">
                                <h5>{this.props.heading}</h5>
                                <span>{this.props.value}</span>
                            </div>
                            {!this.props.isContainer &&
                                <div className="setting-edit">
                                    <FontAwesomeIcon icon={faPencilAlt} />Edit
                                </div>
                            }
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

AccountSetting.propTypes = {
    heading: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    activeSetting: PropTypes.object,
    isContainer: PropTypes.bool
}

export default AccountSetting