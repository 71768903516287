import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Input from '../Input'
import AccountSetting from './AccountSetting'
import { startUsernameChange } from '../../actions/account'

export class UsernameEdit extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            username: this.props.username
        }

        this.canSubmit = this.canSubmit.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading) {
            if (this.props.errors) {
                this.props.errors.filter(e => !e.param).forEach(e => toast.error(e.msg))
            } else {
                toast.success('Username updated!')
                this.props.onCancel()
            }
        }
    }

    handleClick(e) {
        this.setState({
            username: this.props.username
        })
        this.props.onClick(e)
    }

    handleChange(e) {
        this.setState({
            username: e.target.value
        })
    }

    hasValue(str) {
        return !!str && str.length > 0
    }

    canSubmit() {
        return this.hasValue(this.state.username) 
            && this.state.username !== this.props.username
            && !this.props.loading
    }

    handleSubmit(e) {
        e.preventDefault()
        this.props.startUsernameChange(this.state.username)
    }

    render() {
        return (
            <AccountSetting
                heading="Username"
                value={this.props.username}
                loading={this.props.loading}
                onClick={this.handleClick}
                onSubmit={this.handleSubmit}
                onCancel={this.props.onCancel}
                activeSetting={this.props.activeSetting}
                disabled={!this.canSubmit()}>
                <Input
                    label="Username"
                    type="text"
                    autoComplete="off"
                    value={this.state.username}
                    error={this.props.errors && this.props.errors.find(e => e.param === 'username')}
                    onChange={this.handleChange}
                    required
                />
            </AccountSetting>
        )
    }
}

UsernameEdit.propTypes = {
    username: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    activeSetting: PropTypes.object,
    startUsernameChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    username: state.auth.user.username,
    loading: state.account.username.loading,
    errors: state.account.username.errors
})

export default connect(mapStateToProps, { startUsernameChange })(UsernameEdit)