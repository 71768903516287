import axios from 'axios'
import { 
    // login
    LOGIN_SUBMITTED,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    // refresh
    REFRESH_SUBMITTED,
    REFRESH_SUCCESS,
    REFRESH_FAIL,
    // logout
    LOGOUT_SUBMITTED,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL
} from './types'

export const startLogin = ({ email, password }) => async dispatch => {
    try {

        // change the state to login having been submitted
        dispatch({
            type: LOGIN_SUBMITTED
        })

        // post the credentials to the API
        const res = await axios.post(
            '/api/auth/login',
            JSON.stringify({ email, password }),
            { headers: { 'Content-Type': 'application/json' }}
        )

        // login successful, auth cookies (httpOnly) will have been set
        // store the user data in state
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.user
        })

    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: [...(error.response ? error.response.data.errors : [{ msg: error.message }])]
        })
    }
}

export const startLogout = () => async dispatch => {
    try {
        // change the state to logout having been submitted
        dispatch({
            type: LOGOUT_SUBMITTED
        })

        // post the logout request to the API, sending the refresh token cookie
        await axios.post('/api/auth/logout')

        // logout successful
        dispatch({
            type: LOGOUT_SUCCESS
        })
    } catch (error) {
        dispatch({
            type: LOGOUT_FAIL,
            payload: error.response ? error.response.data : { msg: error.message }
        })
    }
}

export const startRefresh = () => async dispatch => {
    try {

        // change the state to refreshing
        dispatch({
            type: REFRESH_SUBMITTED
        })

        // request a new access token from the API
        const res = await axios.post('/api/auth/refresh')

        // refresh success, auth cookies (httpOnly) will have been set
        // store the user data in state
        dispatch({
            type: REFRESH_SUCCESS,
            payload: res.data.user
        })

    } catch (error) {
        dispatch({
            type: REFRESH_FAIL,
            payload: error.response ? error.response.data : { msg: error.message }
        })
    }
}