import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Container, Card } from 'react-bootstrap'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { startEmailUpdate } from '../../actions/account'
import CardHeader from '../CardHeader'
import Loading from '../Loading'

export class EmailUpdate extends React.Component {

    componentDidMount() {
        // start checking the URL token
        this.props.startUpdate(this.props.match.params.token)
    }

    componentDidUpdate(prevProps) {
        // if we've stopped loading
        if (prevProps.loading && !this.props.loading) {

            // navigate to account
            this.props.history.push('/account')

            // if there was errors, toast them
            if (this.props.errors) {
                this.props.errors.forEach((error) => {
                    toast.error(error.msg)
                })
            // otherwise, toast a success
            } else {
                toast.success('Email successfully changed!')
            }
        }
    }

    render() {
        return (
            <Container className="content-center">
                <Card className="card-md card-responsive mb-3 mt-5">
                    <CardHeader icon={ faEnvelope }>
                        Email Update
                    </CardHeader>
                    <Card.Body className="text-center">
                        <Loading>Updating your email address</Loading>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

EmailUpdate.propTypes = {
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object,
    startUpdate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    loading: state.account.emailUpdate.loading,
    errors: state.account.emailUpdate.errors
})

export default connect(mapStateToProps, { startUpdate: startEmailUpdate })(EmailUpdate)