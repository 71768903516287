import { combineReducers } from 'redux'
import { LOGOUT_SUCCESS } from '../actions/types'
import account from './account'
import auth from './auth'
import forgot from './forgot'
import register from './register'

const appReducer = combineReducers({
    account,
    auth,
    forgot,
    register
})

const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS) {
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer