import { 
    REGISTER_SUBMITTED,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    EMAIL_CONFIRM_SUBMITTED,
    EMAIL_CONFIRM_SUCCESS,
    EMAIL_CONFIRM_FAIL
} from '../actions/types'

const initialState = {
    register: {
        loading: false,
        errors: null
    },
    email: {
        loading: false,
        errors: null
    }
}

export default function reduce(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        // register
        case REGISTER_SUBMITTED:
            return {
                ...state,
                register: {
                    loading: true,
                    errors: null
                }
            }
        case REGISTER_SUCCESS:
            return {
                ...state,
                register: {
                    loading: false,
                    errors: null
                }
            }
        case REGISTER_FAIL:
            return {
                ...state,
                register: {
                    loading: false,
                    errors: [...payload]
                }
            }
        // confirm email
        case EMAIL_CONFIRM_SUBMITTED:
            return {
                ...state,
                email: {
                    loading: true,
                    errors: null
                }
            }
        case EMAIL_CONFIRM_SUCCESS:
            return {
                ...state,
                email: {
                    loading: false,
                    errors: null
                }
            }
        case EMAIL_CONFIRM_FAIL:
            return {
                ...state,
                email: {
                    loading: false,
                    errors: [...payload]
                }
            }
        default:
            return state
    }
}