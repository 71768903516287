import axios from 'axios'

export const createRefreshInterceptor = () => {
    axios.interceptors.response.use(response => {
        return response;
    }, async (err) => {
            
        // get the original request to be called once token is refreshed
        const originalRequest = err.config
    
        // if unauthorised response received and this request isn't the retry
        if (err.response.status === 401
                && err.response.config 
                && !err.response.config.url.startsWith('/api/auth/')
                && !err.response.config.url.startsWith('/api/forgot/')
                && err.response.config.url !== '/api/account/email/confirm')
        {
            await axios.post('/api/auth/refresh')
            return await axios(originalRequest)
        }

        // return non-auth errors
        throw err
    })
}