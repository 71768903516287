import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import AccountSetting from './AccountSetting'
import { Alert, Button, Card, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Input from '../Input'
import LoadingButton from '../Button'
import { startAccountDelete } from '../../actions/account'

export class Advanced extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            showModal: false,
            password: '',
            errors: null
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this)
        this.handleDeleteCancel = this.handleDeleteCancel.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading) {
            // toast any non-param errors
            if (this.props.errors) {
                this.props.errors.filter(e => !e.param).forEach(e => toast.error(e.msg))
            } else {
                // this should be unreachable as the router should redirect, but just in case
                this.props.history.push('/login')
            }
        }
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    handleDelete() {
        this.setState((prevState) => ({
            ...prevState,
            showModal: true
        }))
    }

    handleDeleteConfirm() {
        this.props.startAccountDelete(this.state.password)
    }

    handleDeleteCancel() {
        this.setState((prevState) => ({
            ...prevState,
            showModal: false
        }))
    }

    render() {
        return (
            <AccountSetting
                heading="Advanced"
                value=""
                onClick={this.props.onClick}
                onSubmit={() => { console.log('submit') }}
                onCancel={this.props.onCancel}
                activeSetting={this.props.activeSetting}
                isContainer>
                <Alert variant="warning" className="text-center">
                    <Card.Text><b>Deleting your account is permanent!</b></Card.Text>
                    <Card.Text><small>Once deleted, there is no getting it back.</small></Card.Text>
                </Alert>
                <Button variant="danger" className="mb-3" onClick={this.handleDelete}>Delete Account</Button>
                <Modal show={this.state.showModal} onHide={this.handleDeleteCancel} animation={false} centered backdrop={false}>
                    <Modal.Header className="bg-dark text-light justify-content-center">
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete your account?</p>
                        <p><b>This action is irreversible!</b></p>
                        <p>Clicking the confirmation button below will delete your account and sign you out.</p>
                        <Input
                            label="Password"
                            name="password"
                            type="password"
                            autoComplete="off"
                            value={this.state.password}
                            error={this.props.errors && this.props.errors.find(e => e.param === 'password')}
                            onChange={this.handleChange}
                            required
                            className="bg-light"
                        />
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center">
                        <Button variant="secondary" onClick={this.handleDeleteCancel} disabled={ this.props.loading }>Close</Button>
                        <LoadingButton 
                            type="submit" 
                            variant="danger" 
                            onClick={this.handleDeleteConfirm}
                            disabled={ this.state.password.length === 0 } 
                            loading={this.props.loading} 
                            loadingText="Deleting Account">
                            Delete Account
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </AccountSetting>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.account.delete.loading,
    errors: state.account.delete.errors
})

export default connect(mapStateToProps, { startAccountDelete })(withRouter(Advanced))