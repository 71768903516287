// Login
export const LOGIN_SUBMITTED = 'LOGIN_SUBMITTED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

// Logout
export const LOGOUT_SUBMITTED = 'LOGOUT_SUBMITTED'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

// Refresh
export const REFRESH_SUBMITTED = 'REFRESH_SUBMITTED'
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS'
export const REFRESH_FAIL = 'REFRESH_FAIL'

// Registration
export const REGISTER_SUBMITTED = 'REGISTER_SUBMITTED'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'

// Email confirmation
export const EMAIL_CONFIRM_SUBMITTED = 'EMAIL_CONFIRM_SUBMITTED'
export const EMAIL_CONFIRM_SUCCESS = 'EMAIL_CONFIRM_SUCCESS'
export const EMAIL_CONFIRM_FAIL = 'EMAIL_CONFIRM_FAIL'

// Forgot password
export const FORGOT_REQUEST_SUBMITTED = 'FORGOT_REQUEST_SUBMITTED'
export const FORGOT_REQUEST_SUCCESS = 'FORGOT_REQUEST_SUCCESS'
export const FORGOT_REQUEST_FAIL = 'FORGOT_REQUEST_FAIL'
export const FORGOT_CHECK_SUBMITTED = 'FORGOT_CHECK_SUBMITTED'
export const FORGOT_CHECK_SUCCESS = 'FORGOT_CHECK_SUCCESS'
export const FORGOT_CHECK_FAIL = 'FORGOT_CHECK_FAIL'
export const FORGOT_UPDATE_SUBMITTED = 'FORGOT_UPDATE_SUBMITTED'
export const FORGOT_UPDATE_SUCCESS = 'FORGOT_UPDATE_SUCCESS'
export const FORGOT_UPDATE_FAIL = 'FORGOT_UPDATE_FAIL'
export const FORGOT_DELETE_SUBMITTED = 'FORGOT_DELETE_SUBMITTED'
export const FORGOT_DELETE_SUCCESS = 'FORGOT_DELETE_SUCCESS'
export const FORGOT_DELETE_FAIL = 'FORGOT_DELETE_FAIL'

// Account
export const UPLOAD_SUBMITTED = 'UPLOAD_SUBMITTED'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const UPLOAD_FAIL = 'UPLOAD_FAIL'
export const USERNAME_SUBMITTED = 'USERNAME_SUBMITTED'
export const USERNAME_SUCCESS = 'USERNAME_SUCCESS'
export const USERNAME_FAIL = 'USERNAME_FAIL'
export const EMAIL_SUBMITTED = 'EMAIL_SUBMITTED'
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS'
export const EMAIL_FAIL = 'EMAIL_FAIL'
export const EMAIL_UPDATE_SUBMITTED = 'EMAIL_UPDATE_SUBMITTED'
export const EMAIL_UPDATE_SUCCESS = 'EMAIL_UPDATE_SUCCESS'
export const EMAIL_UPDATE_FAIL = 'EMAIL_UPDATE_FAIL'
export const PASSWORD_SUBMITTED = 'PASSWORD_SUBMITTED'
export const PASSWORD_SUCCESS = 'PASSWORD_SUCCESS'
export const PASSWORD_FAIL = 'PASSWORD_FAIL'
export const DELETE_SUBMITTED = 'DELETE_SUBMITTED'
export const DELETE_SUCCESS = 'DELETE_SUCCESS'
export const DELETE_FAIL = 'DELETE_FAIL'