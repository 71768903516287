import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Container, Card } from 'react-bootstrap'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { startDelete } from '../../actions/forgot'
import CardHeader from '../CardHeader'
import Loading from '../Loading'

export class ForgotDelete extends React.Component {

    componentDidMount() {
        // start the password reset delete process
        this.props.startDelete(this.props.match.params.token)
    }

    componentDidUpdate(prevProps) {
        // if the process has completed
        if (prevProps.loading && !this.props.loading) {

            // redirect to the homepage
            this.props.history.push('/')

            // toast the error message
            if (this.props.error) {
                toast.error(this.props.error.msg)
            // toast the success message
            } else {
                toast.success('Password reset request deleted!')
            }
        }
    }

    render() {
        return (
            <Container className="container-page content-center">
                <Card className="card-md card-responsive mb-3">
                    <CardHeader icon={faKey}>
                        Password Reset
                    </CardHeader>
                    <Card.Body className="text-center">
                        <Loading>Deleting password reset token, please wait.</Loading>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

ForgotDelete.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    startDelete: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    loading: state.forgot.delete.loading,
    error: state.forgot.delete.error
})

export default connect(mapStateToProps, { startDelete })(ForgotDelete)