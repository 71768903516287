import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CardHeader = ({ children, icon, type='dark', iconType='white' }) => (
    <Card.Header className={`header-${type} text-center`}>
        <FontAwesomeIcon icon={icon} className={`mr-2 text-${iconType}`} />
        { children }
    </Card.Header>
)

export default CardHeader