import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Input from './Input'

class InputConfirm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            [props.name]: '',
            [props.otherName]: '',
            error: null,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState[this.props.name] !== this.state[this.props.name] || prevState[this.props.otherName] !== this.state[this.props.otherName]) {
            const isDifferent = this.state[this.props.name].length > 0 && this.state[this.props.otherName].length > 0 && this.state[this.props.name] !== this.state[this.props.otherName]
            this.setState((state) => ({
                ...state,
                error: isDifferent ? { msg: this.props.matchError } : null
            }), () => {
                this.props.onMatch(!this.state.error)
            })
        }
    }

    handleChange(e) {
        const name = e.target.name
        const value = e.target.value
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))

        this.props.onChange(e)
    }

    render() {
        return (
            <Fragment>
                <Input
                    type={this.props.type}
                    label={this.props.label}
                    placeholder={this.props.placeholder}
                    required={this.props.required}
                    autoComplete={this.props.autoComplete}
                    name={this.props.name}
                    value={this.state.value}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                    error={this.props.error}
                />
                <Input
                    type={this.props.type}
                    label={this.props.otherLabel}
                    placeholder={this.props.otherPlaceholder}
                    required={this.props.required}
                    autoComplete={this.props.autoComplete}
                    name={this.props.otherName}
                    value={this.state.otherValue}
                    onChange={this.handleChange}
                    disabled={this.props.disabled}
                    error={this.state.error}
                    isInvalid={!!this.state.error}
                />
            </Fragment>
        )
    }

}

InputConfirm.propTypes = {
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onMatch: PropTypes.func.isRequired,
    matchError: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    otherName: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    otherPlaceholder: PropTypes.string,
    label: PropTypes.string,
    otherLabel: PropTypes.string,
    required: PropTypes.bool,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.object
}

export default InputConfirm