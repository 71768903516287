import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
import { Container, Navbar as ReactNavbar, Nav, NavDropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSignInAlt, faSignOutAlt, faAddressCard, faBook } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { startLogout } from '../actions/auth'
import Loading from './Loading'

export const NavIcon = ({ to, icon, title }) => (
    <NavLink to={to} className="nav-link">
        <FontAwesomeIcon icon={icon} className="nav-link-icon" />
        <span className="nav-link-text">{ title }</span>
    </NavLink>
)

export const DropdownToggle = ({ username }) => (
    <Fragment>
        <FontAwesomeIcon icon={faUser} className="dropdown-icon" />
        <span className="dropdown-text">{username}</span>
    </Fragment>
)

export const DropdownItem = ({ title, icon }) => (
    <Fragment>
        <div className="dropdown-item-icon"><FontAwesomeIcon icon={icon} /></div>
        <span>{title}</span>
    </Fragment>
)

export const BaseNavbar = ({ unlink=undefined, children }) => (
    <ReactNavbar variant="dark" sticky="top">
        <Container>
            <Nav.Item as={unlink?Nav.Item:NavLink} to="/" className={`navbar-brand navbar-side ${ unlink && 'navbar-brand-unlinked' }`}>
                <span>ORRISS</span>
                <small>.IO</small>
            </Nav.Item>
            {children}
        </Container>
    </ReactNavbar>
)

export class Navbar extends React.Component {

    constructor(props) {
        super(props)

        this.getPathnameRoot = this.getPathnameRoot.bind(this)
        this.isSignInActiveClass = this.isSignInActiveClass.bind(this)
    }

    componentDidUpdate(prevProps) {
        // if logout action complete
        if (prevProps.loggingOut && !this.props.loggingOut) {
            
            // if there was an error, toast it
            if (this.props.error) {
                toast.error(`Failed to sign out: ${ this.props.error.msg }`)
            // if the user is no longer set, send them to the login page
            } else if (!this.props.user) {
                this.props.history.push('/login')
            // should never happen but if it does, fire off an error
            } else {
                throw new Error('Logout has no error but user still set')
            }
        }
    }

    getPathnameRoot() {
        const parts = this.props.location.pathname.replace(/^\/+|\/+$/g, '').split('/')
        return parts[0].toLowerCase()
    }

    isSignInActiveClass() {
        return ['login', 'register', 'forgot'].includes(this.getPathnameRoot())
    }

    render() {
        this.isSignInActiveClass()
        return (
            <BaseNavbar>
                <Nav className="m-auto">
                    <NavIcon title="Portfolio" to="/portfolio" icon={faBook} />
                </Nav>
                <Nav className="navbar-right">
                    {this.props.loggingIn || this.props.loggingOut || this.props.refreshing ? (
                        <Loading className="text-primary">Loading</Loading>
                    ) : (
                        this.props.user ? (
                            <NavDropdown title={<DropdownToggle username={this.props.user.username}/>} active={this.getPathnameRoot() === 'account'}>
                                <NavDropdown.Item as={NavLink} to="/account">
                                    <DropdownItem title="Manage your account" icon={faAddressCard} />
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <Nav.Item onClick={this.props.startLogout} className="dropdown-item">
                                    <DropdownItem title="Sign Out" icon={faSignOutAlt} />
                                </Nav.Item>
                            </NavDropdown>
                        ) : (
                            <Nav>
                                <Nav.Link as={NavLink} to="/login" className={"nav-link " + (this.isSignInActiveClass() && "active")}>
                                    <FontAwesomeIcon icon={faSignInAlt} className="nav-link-icon" />
                                    <span className="nav-link-text">Sign In</span>
                                </Nav.Link>
                            </Nav>
                        )
                    )}
                </Nav>
            </BaseNavbar>
        )
    }
}

Navbar.propTypes = {
    loggingIn: PropTypes.bool.isRequired,
    loggingOut: PropTypes.bool.isRequired,
    refreshing: PropTypes.bool.isRequired,
    error: PropTypes.object,
    user: PropTypes.object,
    startLogout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    loggingIn: state.auth.login.loading,
    loggingOut: state.auth.logout.loading,
    refreshing: state.auth.refresh.loading,
    error: state.auth.logout.error,
    user: state.auth.user
})

export default connect(mapStateToProps, { startLogout })(withRouter(Navbar))

/*
<NavIcon title="Apps" to="/apps" icon={faDesktop} />
<NavIcon title="Tutorials" to="/tutorials" icon={faGraduationCap} />
<NavIcon title="Games" to="/games" icon={faGamepad} />
*/