import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Alert } from 'react-bootstrap'
import Input from '../Input'
import InputConfirm from '../InputConfirm'
import AccountSetting from './AccountSetting'
import { startEmailChange } from '../../actions/account'

export class EmailEdit extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            email: '',
            email2: '',
            password: '',
            emailsMatch: false
        }

        this.canSubmit = this.canSubmit.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleEmailsMatch = this.handleEmailsMatch.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading) {
            if (this.props.errors) {
                this.props.errors.filter(e => !e.param).forEach(e => toast.error(e.msg))
            } else {
                toast.success('Email update request received, confirmation email sent!')
                this.props.onCancel()
            }
        }
    }

    handleClick(e) {
        this.setState({
            email: '',
            email2: '',
            password: ''
        })
        this.props.onClick(e)
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    handleEmailsMatch(matches) {
        this.setState((prevState) => ({
            ...prevState,
            emailsMatch: matches
        }))
    }

    hasValue(str) {
        return !!str && str.length > 0
    }

    canSubmit() {
        return this.hasValue(this.state.email)
            && this.hasValue(this.state.password)
            && this.state.emailsMatch
            && !this.props.loading
    }

    handleSubmit(e) {
        e.preventDefault()
        this.props.startEmailChange(this.state.email, this.state.password)
    }

    render() {
        return (
            <AccountSetting
                heading="Email"
                value={this.props.email}
                loading={this.props.loading}
                onClick={this.handleClick}
                onSubmit={this.handleSubmit}
                onCancel={this.props.onCancel}
                activeSetting={this.props.activeSetting}
                disabled={!this.canSubmit()}>
                <InputConfirm
                    type="email"
                    onChange={this.handleChange}
                    onMatch={this.handleEmailsMatch}
                    matchError="Emails do not match"
                    required
                    name="email"
                    otherName="email2"
                    label="New email"
                    otherLabel="Confirm new email"
                    autoComplete="off"
                    disabled={this.props.loading}
                    error={this.props.errors && this.props.errors.find(e => e.param === 'email')}
                />
                <Input
                    label="Password"
                    type="password"
                    name="password"
                    value={this.state.password}
                    autoComplete="off"
                    error={this.props.errors && this.props.errors.find(e => e.param === 'password')}
                    onChange={this.handleChange}
                    required
                />
                <Alert variant="warning" className="text-center">
                    <small>You must confirm your new email before it's updated.</small>
                </Alert>
            </AccountSetting>
        )
    }
}

EmailEdit.propTypes = {
    email: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    activeSetting: PropTypes.object,
    startEmailChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    email: state.auth.user.email,
    loading: state.account.email.loading,
    errors: state.account.email.errors
})

export default connect(mapStateToProps, { startEmailChange })(EmailEdit)