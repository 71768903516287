import { 
    // login
    LOGIN_SUBMITTED,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    
    // refresh
    REFRESH_SUBMITTED,
    REFRESH_SUCCESS,
    REFRESH_FAIL,
    
    // logout
    LOGOUT_SUBMITTED,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,

    // profile updates
    UPLOAD_SUCCESS,
    USERNAME_SUCCESS,
    EMAIL_UPDATE_SUCCESS,

    // account deletion
    DELETE_SUCCESS
} from '../actions/types'

const initialState = {
    user: null,
    login: {
        loading: false,
        errors: null
    },
    logout: {
        loading: false,
        error: null
    },
    refresh: {
        loading: false,
        error: null
    }
}

export default function reduce(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        // login
        case LOGIN_SUBMITTED:
            return {
                ...state,
                login: {
                    loading: true,
                    errors: null
                }
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: { ...payload },
                login: {
                    ...initialState.login
                }
            }
        case LOGIN_FAIL:
            return {
                ...state,
                user: initialState.user,
                login: {
                    errors: [ ...payload ],
                    loading: false
                }
            }
        // logout
        case LOGOUT_SUBMITTED:
            return {
                ...state,
                logout: {
                    ...state.logout,
                    loading: true
                }
            }
        case DELETE_SUCCESS:
        case LOGOUT_SUCCESS:
            return {
                ...initialState
            }
        case LOGOUT_FAIL:
            return {
                ...state,
                logout: {
                    loading: false,
                    error: { ...payload }
                }
            }
        // refresh
        case REFRESH_SUBMITTED:
            return {
                ...state,
                refresh: {
                    ...state.refresh,
                    loading: true
                }
            }
        case REFRESH_SUCCESS:
            return {
                ...state,
                user: { ...payload },
                refresh: {
                    ...initialState.refresh
                }
            }
        case REFRESH_FAIL:
            return {
                ...state,
                user: initialState.user,
                refresh: {
                    loading: false,
                    error: { ...payload }
                }
            }
        case UPLOAD_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    hasProfilePic: true // TODO test the hasProfilePic gets set on UPLOAD_SUCCESS
                }
            }
        case USERNAME_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    username: payload // TODO test this
                }
            }
        case EMAIL_UPDATE_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    email: payload // TODO test this
                }
            }
        default:
            return state
    }
}