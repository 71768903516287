import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Input from '../Input'
import { faKey, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Container, Card } from 'react-bootstrap'
import CardHeader from '../CardHeader'
import Button from '../Button'
import Hint from '../Hint'
import { startRequest } from '../../actions/forgot'

export class ForgotRequest extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            email: '',
            sent: false
        }

        this.canSubmit = this.canSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    // handle component updates
    componentDidUpdate(prevProps) {
        // if password reset finished
        if (prevProps.loading && !this.props.loading) {

            // if there is an error array
            if (this.props.errors) {
                // throw the first non-param error
                const e = this.props.errors.find(e => !e.param)
                if (e) { throw new Error(e.msg) }
            // success
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    sent: true
                }))
            }
        }
    }

    handleChange(e) {
        const value = e.target.value
        this.setState((prevState) => ({ 
            ...prevState,
            email: value
        }))
    }

    canSubmit() {
        return !!this.state.email && this.state.email.length > 0 && !this.props.loading
    }

    handleSubmit(e) {
        // prevent the form submission
        e.preventDefault()

        // send password reset email
        this.props.startRequest({ email: this.state.email })
    }

    render() {
        const headerText = this.state.sent ? 'Password reset email sent' : 'Forgot your password?'
        const headerIcon = this.state.sent ? faEnvelope : faKey

        return (
            <Container className="container-page content-center">
                <Card className="card-md card-responsive mb-3">
                    <CardHeader icon={ headerIcon }>
                        {headerText}
                    </CardHeader>
                    <Card.Body>
                        {this.state.sent ? (
                            <Fragment>
                                <Card.Text>We've received your password reset request!</Card.Text>
                                <Card.Text>Please check your inbox at <b>{this.state.email}</b> and click the reset password link!</Card.Text>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Hint className="mt-1 mb-4">Provide your email and we'll send the instructions</Hint>
                                <form onSubmit={this.handleSubmit}>
                                    <Input
                                        required
                                        placeholder="Email"
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        disabled={this.props.loading}
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        error={this.props.errors && this.props.errors.find(e => e.param === 'email')}
                                    />
                                    <Button type="submit" block disabled={ !this.canSubmit() } loading={this.props.loading}>
                                        Send reset link
                                    </Button>
                                </form>
                            </Fragment>
                        )}
                    </Card.Body>
                </Card>
                {!this.state.sent && 
                    <Link to="/login" className="mb-3">
                        <small>Sign In</small>
                    </Link>
                }
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.forgot.request.loading,
    errors: state.forgot.request.errors
})

ForgotRequest.propTypes = {
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.array,
    startRequest: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { startRequest })(ForgotRequest)