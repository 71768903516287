import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Container, Card } from 'react-bootstrap'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { startConfirmEmail } from '../../actions/register'
import Loading from '../Loading'
import CardHeader from '../CardHeader'

export class ConfirmEmail extends React.Component {

    componentDidMount() {
        // start the email confirmation process on mount
        this.props.startConfirmEmail(this.props.match.params.token)
    }

    componentDidUpdate(prevProps) {
        // if confirmation complete (no longer loading)
        if (prevProps.loading && !this.props.loading) {

            // navigate to the login page
            this.props.history.push('/login')

            // if there was an error
            if (this.props.errors) {

                // ensure there are errors and toast them
                if (this.props.errors.length > 0) {
                    this.props.errors.forEach(e => toast.error(e.msg))
                // shouldn't get here but if we do, raise an error
                } else {
                    throw new Error('ConfirmEmail finished loading and provided an empty errors array, should be null on no errors, or an error is missing from the array')
                }
            // successfully confirmed email
            } else {
                // toast a success message
                toast.success('Email confirmed! Please sign in...')
            }
        }
    }

    render() {
        return (
            <Container className="container-page content-center">
                <Card className="card-md card-responsive mb-3">
                    <CardHeader icon={ faEnvelope }>Email Confirmation</CardHeader>
                    <Card.Body className="text-center">
                        <Loading>Confirming your email address, please wait.</Loading>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

ConfirmEmail.propTypes = {
    errors: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    startConfirmEmail: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    loading: state.register.email.loading,
    errors: state.register.email.errors
})

export default connect(mapStateToProps, { startConfirmEmail })(ConfirmEmail)