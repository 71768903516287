import { 
    UPLOAD_SUBMITTED,
    UPLOAD_SUCCESS,
    UPLOAD_FAIL,
    USERNAME_SUBMITTED,
    USERNAME_SUCCESS,
    USERNAME_FAIL,
    EMAIL_SUBMITTED,
    EMAIL_SUCCESS,
    EMAIL_FAIL,
    EMAIL_UPDATE_SUBMITTED,
    EMAIL_UPDATE_SUCCESS,
    EMAIL_UPDATE_FAIL,
    PASSWORD_SUBMITTED,
    PASSWORD_SUCCESS,
    PASSWORD_FAIL,
    DELETE_SUBMITTED,
    DELETE_SUCCESS,
    DELETE_FAIL
} from '../actions/types'

const initialState = {
    upload: {
        loading: false,
        error: null
    },
    username: {
        loading: false,
        errors: null
    },
    email: {
        loading: false,
        errors: null
    },
    emailUpdate: {
        loading: false,
        errors: null
    },
    password: {
        loading: false,
        errors: null
    },
    delete: {
        loading: false,
        errors: null
    }
}

export default function reduce(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case UPLOAD_SUBMITTED:
            return {
                ...state,
                upload: {
                    loading: true,
                    error: null
                }
            }
        case UPLOAD_SUCCESS:
            return {
                ...state,
                upload: {
                    ...initialState.upload
                }
            }
        case UPLOAD_FAIL:
            return {
                ...state,
                upload: {
                    loading: false,
                    error: { ...payload }
                }
            }
        case USERNAME_SUBMITTED:
            return {
                ...state,
                username: {
                    loading: true,
                    errors: null
                }
            }
        case USERNAME_SUCCESS:
            return {
                ...state,
                username: {
                    ...initialState.username
                }
            }
        case USERNAME_FAIL:
            return {
                ...state,
                username: {
                    loading: false,
                    errors: [ ...payload ]
                }
            }
        case EMAIL_SUBMITTED:
            return {
                ...state,
                email: {
                    loading: true,
                    errors: null
                }
            }
        case EMAIL_SUCCESS:
            return {
                ...state,
                email: {
                    ...initialState.email
                }
            }
        case EMAIL_FAIL:
            return {
                ...state,
                email: {
                    loading: false,
                    errors: [ ...payload ]
                }
            }
        case EMAIL_UPDATE_SUBMITTED:
            return {
                ...state,
                emailUpdate: {
                    loading: true,
                    errors: null
                }
            }
        case EMAIL_UPDATE_SUCCESS:
            return {
                ...state,
                emailUpdate: {
                    ...initialState.emailUpdate
                }
            }
        case EMAIL_UPDATE_FAIL:
            return {
                ...state,
                emailUpdate: {
                    loading: false,
                    errors: [...payload]
                }
            }
        case PASSWORD_SUBMITTED:
            return {
                ...state,
                password: {
                    loading: true,
                    errors: null
                }
            }
        case PASSWORD_SUCCESS:
            return {
                ...state,
                password: {
                    ...initialState.password
                }
            }
        case PASSWORD_FAIL:
            return {
                ...state,
                password: {
                    loading: false,
                    errors: [ ...payload ]
                }
            }
        case DELETE_SUBMITTED:
            return {
                ...state,
                delete: {
                    loading: true,
                    errors: null
                }
            }
        case DELETE_SUCCESS:
            return {
                ...state,
                delete: {
                    ...initialState.delete
                }
            }
        case DELETE_FAIL:
            return {
                ...state,
                delete: {
                    loading: false,
                    errors: [ ...payload ]
                }
            }
        default:
            return state
    }
}