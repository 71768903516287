import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Provider, connect } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import store from './store'
import ErrorBoundary from './components/ErrorBoundary'
import { startRefresh } from './actions/auth'
import { createRefreshInterceptor } from './utils/axios'
import PublicRoute from './routes/PublicRoute'
import PrivateRoute from './routes/PrivateRoute'
import ScrollToTop from './components/ScrollToTop'
import Navbar from './components/Navbar'
import Register from './components/auth/Register'
import ConfirmEmail from './components/auth/ConfirmEmail'
import Login from './components/auth/Login'
import Account from './components/account/Account'
import EmailUpdate from './components/account/EmailUpdate'
import ForgotRequest from './components/forgot/ForgotRequest'
import ForgotUpdate from './components/forgot/ForgotUpdate'
import ForgotDelete from './components/forgot/ForgotDelete'
import Portfolio from './components/Portfolio'
import NotFound from './components/NotFound'
import Loading from './components/Loading'

// styles
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import 'react-toastify/dist/ReactToastify.css'
import './styles/styles.scss'

createRefreshInterceptor()

export class Content extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            initialised: false
        }
    }

    componentDidMount() {
        this.props.startRefresh()
    }

    componentDidUpdate(prevProps) {
        // if refreshing is complete
        if (prevProps.refreshing && !this.props.refreshing) {
            // app is initialised, should now mount the router component
            this.setState((prevState) => ({
                ...prevState,
                initialised: true
            }))
        }
    }

    render() {
        if (!this.state.initialised) {
            return (
                <div className="content-center">
                    <div className="flex-row align-items-center">
                        <Loading iconSize={30}>
                            <h4>Loading</h4>
                        </Loading>
                    </div>
                </div>
            )
        }

        return (
            <Router>
                <ScrollToTop>
                    <ToastContainer position="top-center" autoClose={5000} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange draggable={false} pauseOnHover />
                    <Navbar />
                    <Switch>
                        <Route exact path="/" component={() => (<Redirect to="/portfolio" />)} />
                        <PublicRoute exact path="/register" component={Register}/>
                        <PublicRoute exact path="/register/confirm/:token" component={ConfirmEmail}/>
                        <PublicRoute exact path="/login" component={Login}/>
                        <PublicRoute exact path="/forgot" component={ForgotRequest}/>
                        <PublicRoute exact path="/forgot/:token" component={ForgotUpdate} />
                        <Route exact path="/forgot/delete/:token" component={ForgotDelete} />
                        <PrivateRoute exact path="/account" component={Account} />
                        <Route exact path="/account/email/:token" component={EmailUpdate} />
                        <Route path="/portfolio" component={Portfolio}/>
                        <NotFound />
                    </Switch>
                </ScrollToTop>
            </Router>
        )
    }
}

const mapStateToProps = state => ({
    refreshing: state.auth.refresh.loading
})

Content.propTypes = {
    refreshing: PropTypes.bool.isRequired,
    startRefresh: PropTypes.func.isRequired
}

export default class Root extends React.Component {
    render() {
        return (
            <ErrorBoundary>
                <Provider store={store}>
                    <RootContent />
                </Provider>
            </ErrorBoundary>
        )
    }
}

const RootContent = connect(mapStateToProps, { startRefresh })(Content)
ReactDOM.render(<Root />, document.getElementById('root'))

// TODO Check if sign out fails after access token expires (failed on laptop)