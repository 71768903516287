import { 
    // request
    FORGOT_REQUEST_SUBMITTED,
    FORGOT_REQUEST_SUCCESS,
    FORGOT_REQUEST_FAIL,
    // check
    FORGOT_CHECK_SUBMITTED,
    FORGOT_CHECK_SUCCESS,
    FORGOT_CHECK_FAIL,
    // update
    FORGOT_UPDATE_SUBMITTED,
    FORGOT_UPDATE_SUCCESS,
    FORGOT_UPDATE_FAIL,
    // delete
    FORGOT_DELETE_SUBMITTED,
    FORGOT_DELETE_SUCCESS,
    FORGOT_DELETE_FAIL
} from '../actions/types'

export const initialState = {
    request: {
        loading: false,
        errors: null
    },
    check: {
        loading: false,
        error: null
    },
    update: {
        loading: false,
        errors: null
    },
    delete: {
        loading: false,
        error: null
    }
}

export const reducer = function (state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case FORGOT_REQUEST_SUBMITTED:
            return {
                ...state,
                request: {
                    loading: true,
                    errors: null
                }
            }
        case FORGOT_REQUEST_SUCCESS:
            return {
                ...state,
                request: {
                    ...initialState.request
                }
            }
        case FORGOT_REQUEST_FAIL:
            return {
                ...state,
                request: {
                    loading: false,
                    errors: [ ...payload ]
                }
            }
        case FORGOT_CHECK_SUBMITTED:
            return {
                ...state,
                check: {
                    ...initialState.check,
                    loading: true
                }
            }
        case FORGOT_CHECK_SUCCESS:
            return {
                ...state,
                check: {
                    ...initialState.check
                }
            }
        case FORGOT_CHECK_FAIL:
            return {
                ...state,
                check: {
                    loading: false,
                    error: { ...payload }
                }
            }
        case FORGOT_UPDATE_SUBMITTED:
            return {
                ...state,
                update: {
                    ...initialState.update,
                    loading: true
                }
            }
        case FORGOT_UPDATE_SUCCESS:
            return {
                ...state,
                update: {
                    ...initialState.update,
                }
            }
        case FORGOT_UPDATE_FAIL:
            return {
                ...state,
                update: {
                    loading: false,
                    errors: [ ...payload ]
                }
            }
        case FORGOT_DELETE_SUBMITTED:
            return {
                ...state,
                delete: {
                    ...initialState.delete,
                    loading: true
                }
            }
        case FORGOT_DELETE_SUCCESS:
            return {
                ...state,
                delete: {
                    ...initialState.delete
                }
            }
        case FORGOT_DELETE_FAIL:
            return {
                ...state,
                delete: {
                    loading: false,
                    error: { ...payload }
                }
            }
        default:
            return state
    }
}

export default reducer