import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DropzoneArea } from 'material-ui-dropzone'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { startUpload } from '../../actions/account'
import Loading from '../Loading'
import ProfilePic from '../ProfilePic'

export class ProfilePicture extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            imageTimestamp: Date.now()
        }

        this.fileSizeLimitBytes = Number(process.env.REACT_APP_FILE_SIZE_LIMIT)
        this.fileSizeLimitString = parseFloat(this.fileSizeLimitBytes/1048576).toFixed(2).replace('.00', '') + 'MB'

        this.handleFileAdded = this.handleFileAdded.bind(this)
        this.handleInputFileAdded = this.handleInputFileAdded.bind(this)
        this.handleFileRejected = this.handleFileRejected.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading) {
            if (!this.props.error) {
                this.setState((prevState) => ({
                    ...prevState,
                    imageTimestamp: Date.now()
                }))
                toast.success('Profile picture uploaded!')
            } else {
                toast.error(this.props.error.msg)
            }
        }
    }

    handleFileAdded(files) {
        if (files.length === 0) {
            return
        }

        this.props.startUpload(files[0])
    }

    handleInputFileAdded(e) {
        const files = e.target.files
        if (files.length === 0) {
            return
        }

        if (files[0].size >= this.fileSizeLimitBytes) {
            toast.error(`File is larger than the ${ this.fileSizeLimitString } limit`)
            return
        }

        this.props.startUpload(files[0])
    }

    handleFileRejected(files) {
        if (files.length === 0) {
            return
        }

        if (files[0].size >= this.fileSizeLimitBytes) {
            toast.error(`File is larger than the ${ this.fileSizeLimitString } limit`)
        } else {
            toast.error('File rejected')
        }
    }

    render() {
        return (
            <div className="profile-pic-edit">
                <div className="preview mr-3">
                    <ProfilePic userId={this.props.userId} none={!this.props.hasProfilePic} className="preview-img" />
                    {!this.props.loading &&
                        <div className="upload-icon">
                            <FontAwesomeIcon icon={faUpload} />
                        </div>
                    }
                    <input type="file" className="browse-btn" onChange={ this.handleInputFileAdded } accept="image/png,image/jpeg" />
                    {this.props.loading && (
                        <div className="img-overlay">
                            <Loading iconSize={15} className="flex-column">Uploading</Loading>
                        </div>
                    )}
                </div>
                <div className="dropzone-container">
                    <DropzoneArea
                        dropzoneProps={{disabled:this.props.loading}}
                        acceptedFiles={['image/png', 'image/jpeg']}
                        onChange={this.handleFileAdded}
                        onDropRejected={this.handleFileRejected}
                        filesLimit={1} 
                        showPreviews={false} 
                        showAlerts={false}
                        showPreviewsInDropzone={false}
                        maxFileSize={this.fileSizeLimitBytes}
                        dropzoneText={`Drag and drop image here (${this.fileSizeLimitString} limit)`}
                        dropzoneClass="dropzone"
                        dropzoneParagraphClass="dropzone-text"
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.account.upload.loading,
    error: state.account.upload.error,
    userId: state.auth.user._id,
    hasProfilePic: state.auth.user.hasProfilePic
})

ProfilePicture.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    userId: PropTypes.string.isRequired,
    hasProfilePic: PropTypes.bool.isRequired,
    startUpload: PropTypes.func.isRequired
}

export default connect(mapStateToProps, { startUpload })(ProfilePicture)