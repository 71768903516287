import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Alert } from 'react-bootstrap'
import Input from '../Input'
import InputConfirm from '../InputConfirm'
import AccountSetting from './AccountSetting'
import { startPasswordChange } from '../../actions/account'

export class PasswordEdit extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            password: '',
            newPassword: '',
            newPassword2: '',
            passwordsMatch: false
        }

        this.canSubmit = this.canSubmit.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePasswordMatch = this.handlePasswordMatch.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading) {
            if (this.props.errors) {
                this.props.errors.filter(e => !e.param).forEach(e => toast.error(e.msg))
            } else {
                toast.success('Password changed!')
                this.props.onCancel()
            }
        }
    }

    handleClick(e) {
        this.setState({
            password: '',
            newPassword: '',
            newPassword2: ''
        })
        this.props.onClick(e)
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    handlePasswordMatch(matches) {
        this.setState((prevState) => ({
            ...prevState,
            passwordsMatch: matches
        }))
    }

    hasValue(str) {
        return !!str && str.length > 0
    }

    canSubmit() {
        return this.hasValue(this.state.password) && 
            this.hasValue(this.state.newPassword) && 
            this.hasValue(this.state.newPassword2) && 
            this.state.passwordsMatch &&
            !this.props.loading
    }

    handleSubmit(e) {
        e.preventDefault()
        this.props.startPasswordChange(this.state.password, this.state.newPassword)
    }

    render() {
        return (
            <AccountSetting
                heading="Password"
                value="•••••••••••••••"
                loading={this.props.loading}
                onClick={this.handleClick}
                onSubmit={this.handleSubmit}
                onCancel={this.props.onCancel}
                activeSetting={this.props.activeSetting}
                disabled={!this.canSubmit()}>
                <Input
                    label="Current password"
                    type="password"
                    name="password"
                    value={this.state.password}
                    error={this.props.errors && this.props.errors.find(e => e.param === 'password')}
                    onChange={this.handleChange}
                    autoComplete="off"
                    required
                />
                <InputConfirm
                    type="password"
                    onChange={this.handleChange}
                    onMatch={this.handlePasswordMatch}
                    matchError="Passwords do not match"
                    required
                    name="newPassword"
                    otherName="newPassword2"
                    label="New password"
                    otherLabel="Confirm new password"
                    autoComplete="off"
                    disabled={this.props.loading}
                    error={this.props.errors && this.props.errors.find(e => e.param === 'newPassword')}
                />
                <Alert variant="warning" className="text-center">
                    <small><b>Warning: </b>Changing your password will sign you out of all other devices!</small>
                </Alert>
            </AccountSetting>
        )
    }
}

PasswordEdit.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    activeSetting: PropTypes.object,
    startPasswordChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    loading: state.account.password.loading,
    errors: state.account.password.errors
})

export default connect(mapStateToProps, { startPasswordChange })(PasswordEdit)