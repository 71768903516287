import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import { Form } from 'react-bootstrap'

// TODO test the autoComplete="off"

class Input extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            valid: true,
            feedback: '',
            autoComplete: this.generateAutoComplete()
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidUpdate(prevProps) {

        // if the error changed
        if (prevProps.error !== this.props.error) {
            this.setState((prevState) => ({
                ...prevState,
                valid: !this.props.error,
                feedback: this.props.error ? this.props.error.msg : ''
            }))
        }

        // if the autoComplete changed
        if (prevProps.autoComplete !== this.props.autoComplete) {
            this.setState((prevState) => ({
                ...prevState,
                autoComplete: this.generateAutoComplete()
            }))
        }
    }

    generateAutoComplete() {
        return this.props.autoComplete === 'off' ? Date.now() + nanoid() : this.props.autoComplete
    }

    handleChange(e) {
        // if showing an error, remove it
        if (!this.state.valid) {
            this.setState((prevState) => ({
                ...prevState,
                valid: true,
                feedback: ''
            }))
        }

        // pass the event back up to the parent
        this.props.onChange(e)
    }

    render() {
        return (
            
            <Form.Group className="mb-2">
                {this.props.label &&
                    <Form.Label className={`mb-0 ${this.props.isInvalid && 'text-danger'}`}>{this.props.label}</Form.Label>
                }
                <Form.Control {...this.props} isInvalid={this.props.isInvalid || !this.state.valid} onChange={this.handleChange} autoComplete={this.state.autoComplete} />
                <Form.Text className="text-right text-danger mt-0 mr-1">{this.state.feedback}&nbsp;</Form.Text>
            </Form.Group>
        )
    }
}

Input.propTypes = {
    error: PropTypes.object,
    isInvalid: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
    autoComplete: PropTypes.string
}

export default Input