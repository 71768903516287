import PropTypes from 'prop-types'

const ProfilePic = ({ userId, none, timestamp=Date.now(), ...props }) => {
    let url = `/resources/images/${none ? 'profile.png' : `profiles/${userId}.png`}`
    if (!none) url += `?t=${timestamp}`
    return (
        <img {...props} src={url} alt="" />
    )
}

ProfilePic.propTypes = {
    userId: PropTypes.string.isRequired,
    none: PropTypes.bool.isRequired,
    timestamp: PropTypes.number
}

export default ProfilePic