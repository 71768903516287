import React, { Fragment } from 'react'
import { Container, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug } from '@fortawesome/free-solid-svg-icons'
import { BaseNavbar } from './Navbar'
import { logError } from '../utils/error'

class ErrorBoundary extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = { hasError: false, errorMessage: undefined }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message }
    }

    componentDidCatch(error, errorInfo) {
        // asynchronously send the error to the API
        logError(error.stack, errorInfo.componentStack)
    }

    render() {
        if (this.state.hasError) {
            return (
                <Fragment>
                    <BaseNavbar unlink />
                    <Container className="d-flex justify-content-center">
                        <Card className="card-md card-responsive mb-5 mt-5">
                            <Card.Header as="h3" className="font-weight-bold header-danger text-center">Fatal Error</Card.Header>
                            <Card.Body>
                                <Card.Title className="text-center">Oops, something went wrong...</Card.Title>
                                <Card.Text className="text-center" style={{fontSize:'4rem'}}>
                                    <FontAwesomeIcon icon={faBug} />
                                </Card.Text>
                                <Card.Text>We're very sorry but an unexpected error has occured. We have sent an error report to our servers and will look into it.</Card.Text>
                                <Card.Text>Please try again, and if the problem persists then bear with us whilst we fix the cause.</Card.Text>
                            </Card.Body>
                            {this.state.errorMessage && (
                                <Card.Footer>
                                    <Card.Text className="font-weight-bold mb-0">Error Message</Card.Text>
                                    <Card.Text>{this.state.errorMessage}</Card.Text>
                                </Card.Footer>
                            )}
                        </Card>
                    </Container>
                </Fragment>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary