import React from 'react'
import { Container, Card, ListGroup } from 'react-bootstrap'
import { faUserCog } from '@fortawesome/free-solid-svg-icons'
import CardHeader from '../CardHeader'
import ProfilePictureEdit from './ProfilePictureEdit'
import UsernameEdit from './UsernameEdit'
import EmailEdit from './EmailEdit'
import PasswordEdit from './PasswordEdit'
import Advanced from './Advanced'

export class Account extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeSetting: null
        }

        this.handleEditClick = this.handleEditClick.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
    }

    handleEditClick(setting) {
        this.setState((prevState) => ({
            ...prevState,
            activeSetting: setting
        }))
    }

    handleEditCancel() {
        this.setState((prevState) => ({
            ...prevState,
            activeSetting: null
        }))
    }

    render() {
        return (
            <Container className="container-page content-center">
                <Card className="card-lg card-responsive mb-4 card-sm-full">
                    <CardHeader icon={ faUserCog } >
                        Your account
                    </CardHeader>
                    <Card.Body>
                        <ProfilePictureEdit />
                        <Card bg="light" className="mt-3 shadow-none border-0">
                            <ListGroup>
                                <ListGroup.Item className="p-0">
                                    <UsernameEdit
                                        onClick={this.handleEditClick}
                                        onCancel={this.handleEditCancel}
                                        activeSetting={this.state.activeSetting} />
                                </ListGroup.Item>
                                <ListGroup.Item className="p-0">
                                    <EmailEdit
                                        onClick={this.handleEditClick}
                                        onCancel={this.handleEditCancel}
                                        activeSetting={this.state.activeSetting} />
                                </ListGroup.Item>
                                <ListGroup.Item className="p-0">
                                    <PasswordEdit
                                        onClick={this.handleEditClick}
                                        onCancel={this.handleEditCancel}
                                        activeSetting={this.state.activeSetting} />
                                </ListGroup.Item>
                                <ListGroup.Item className="p-0">
                                    <Advanced
                                        onClick={this.handleEditClick}
                                        onCancel={this.handleEditCancel}
                                        activeSetting={this.state.activeSetting} />
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

export default Account