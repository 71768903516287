import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { faChrome } from '@fortawesome/free-brands-svg-icons'
import { Container, Row } from 'react-bootstrap'
import Portfolio from '../Portfolio'

class MeetingCaller extends React.Component {
    render() {
        return (
            <div className="portfolio-page">
                {/* Header */}
                <Portfolio.Header icon={faChrome} title="Meeting Caller">
                    <Portfolio.HeaderLink to="/portfolio/meeting-caller" exact>Overview</Portfolio.HeaderLink>
                    <Portfolio.HeaderLink to="/portfolio/meeting-caller/privacy">Privacy</Portfolio.HeaderLink>
                </Portfolio.Header>
                <Switch>

                    {/* Overview */}
                    <Route exact path="/portfolio/meeting-caller">
                        <Portfolio.Carousel>
                            <Portfolio.CarouselImg src="/images/meet-caller/meetings.jpg" alt="Meetings" />
                            <Portfolio.CarouselImg src="/images/meet-caller/call.jpg" alt="Call" />
                            <Portfolio.CarouselImg src="/images/meet-caller/sign-in.jpg" alt="Sign In" />
                            <Portfolio.CarouselImg src="/images/meet-caller/settings.jpg" alt="Settings" />
                        </Portfolio.Carousel>
                        <Portfolio.Overview>
                            <Portfolio.Overview.Main>
                                <p>Do you keep turning up to your Google Meet calls 5 minutes late or until someone messages you asking you where you are?</p>
                                <p>Meeting Caller is a Google Chrome extension that provides a Skype-like caller for your Google Meet calendar events. The extension will play a phone ringing sound to alert you that a meeting has started.</p>
                                <p>Offering <i>answer</i> and <i>decline</i> buttons in the caller UI, clicking the <i>answer</i> button will open the Google Meet in a new browser tab for convenience.</p>
                                <p>Your calendar is checked every 10 minutes for new meetings or updates to existing meetings.</p>
                                <p>By default, Meeting Caller will alert you for all Meet status' except for <i>declined</i>, this can be changed within the settings.</p>
                            </Portfolio.Overview.Main>
                            <Portfolio.Overview.Sidebar>
                                <img src="/images/chrome-store.png" alt="Available in the Chrome Web Store" />
                                <Portfolio.Property title="Version">1.2.0</Portfolio.Property>
                                <Portfolio.Property title="Updated">20th April 2021</Portfolio.Property>
                                <Portfolio.Property title="Language">English (United Kingdom)</Portfolio.Property>
                            </Portfolio.Overview.Sidebar>
                        </Portfolio.Overview>
                    </Route>

                    {/* Privacy Policy */}
                    <Route path="/portfolio/meeting-caller/privacy">
                        <Container className="container-page">
                            <h3 className="text-center">Privacy Policy</h3>
                            
                            <hr />
                            
                            <h5>Contact Details</h5>
                            <p className="mb-0"><b>Name</b></p>
                            <p>Kevin Orriss</p>
                            <p className="mb-0"><b>Email</b></p>
                            <p>kevinorrissdev@gmail.com</p>
                            
                            <hr />

                            <h5>The type of personal information we collect</h5>
                            <p>We currently collect and process the following information:</p>
                            <ul>
                                <li>
                                    <span>Your Google Calendar meeting information which includes:</span>
                                    <ul>
                                        <li>Meeting Name</li>
                                        <li>Meeting Time</li>
                                        <li>Response Status (Accepted, Declined, Maybe, Needs Action)</li>
                                        <li>Number of attendees</li>
                                        <li>Meeting URL</li>
                                    </ul>
                                </li>
                            </ul>

                            <hr />

                            <h5>How we get the personal information and why we have it</h5>
                            <p>All of the meeting information is provided to us by the Google Calendar API, after you provide access.</p>

                            <p>We use the information that you have given us in order to:</p>
                            <ul>
                                <li>Display your meeting information back to you</li>
                                <li>Use the meeting times to set alarms</li>
                                <li>Open the Google Meet in a new browser tab</li>
                            </ul>

                            <p>We will not share this information with any third-party organisations or individuals.</p>

                            <p>Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this information are:</p>
                            <ul>
                                <li>
                                    <span>Your consent. You are able to remove your consent at any time. You can do this by:</span>
                                    <ul>
                                        <li>Signing out of the Meeting Caller extension</li>
                                        <li>
                                            <span>Remove account access by following these Google instructions:</span>
                                            <ul>
                                                <li>Go to the Security section of your Google Account.</li>
                                                <li>Under “Third-party apps with account access,” select Manage third-party access.</li>
                                                <li>Select the app or service you want to remove.</li>
                                                <li>Select Remove Access.</li>
                                            </ul>
                                        </li>
                                        <li>Uninstalling the Meeting Caller extension</li>
                                    </ul>
                                </li>
                            </ul>

                            <hr />

                            <h5>How we store your personal information</h5>
                            <p>We do not store any of your personal information. All of your calendar information is discarded when:</p>
                            <ul>
                                <li>You sign out</li>
                                <li>The meeting finishes</li>
                                <li>Uninstall the Meeting Caller extension</li>
                            </ul>

                            <p>Your authentication token <b>is not</b> stored in browser storage, as reccomended by Google. Your authentication token is stored in a local scope variable which is not accessible outside of the Meeting Caller extension.</p>

                            <hr />

                            <h5>Your data protection rights</h5>
                            <p>Under data protection law, you have rights including:</p>

                            <p><b>Your right of access:</b> You have the right to ask us for copies of your personal information.</p>
                            <p><b>Your right to rectification:</b> You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.</p>
                            <p><b>Your right to erasure:</b> You have the right to ask us to erase your personal information in certain circumstances.</p>
                            <p><b>Your right to restriction of processing:</b> You have the right to ask us to restrict the processing of your personal information in certain circumstances.</p>
                            <p><b>Your right to object to processing:</b> You have the the right to object to the processing of your personal information in certain circumstances.</p>
                            <p><b>Your right to data portability:</b> You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</p>
                            
                            <p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</p>

                            <p>Please contact us at <b>kevinorrissdev@gmail.com</b> if you wish to make a request.</p>

                            <hr />

                            <h5>How to complain</h5>
                            <p>If you have any concerns about our use of your personal information, you can make a complaint to us at <b>kevinorrissdev@gmail.com</b>.</p>
                            <p>You can also complain to the ICO if you are unhappy with how we have used your data.</p>

                            <p>The ICO’s address:</p>

                            <p>
                                <span>Information Commissioner’s Office</span><br/>
                                <span>Wycliffe House</span><br/>
                                <span>Water Lane</span><br/>
                                <span>Wilmslow</span><br/>
                                <span>Cheshire</span><br/>
                                <span>SK9 5AF</span><br/>
                            </p>

                            <p>Helpline number: 0303 123 1113</p>
                            <p>ICO website: <a href="https://ico.org.uk/" target="_blank">https://ico.org.uk/</a></p>

                        </Container>
                    </Route>
                </Switch>
            </div>
        )
    }
}

export default MeetingCaller

/*

<Container className="d-flex flex-row">
    <div className="flex-grow-1 p-3">
        <p>Do you keep turning up to your Google Meet calls 5 minutes late or until someone messages you asking you where you are?</p>
        <p>Meeting Caller is a Google Chrome extension that provides a Skype-like caller for your Google Meet calendar events. The extension will play a phone ringing sound to alert you that a meeting has started.</p>
        <p>Offering <i>answer</i> and <i>decline</i> buttons in the caller UI, clicking the "answer" button will open the Google Meet in a new browser tab for convenience.</p>
        <p>Your calendard is checked every 10 minutes for new meetings or updates to existing meetings.</p>
        <p>By default, Meeting Caller will alert you for all Meet status' except for <i>declined</i>, this can be changed within the settings.</p>
    </div>
    <div className="p-3" style={{ width: '25rem', borderLeft: '1px solid #CCC' }}>
        <a href="/" target="_blank">
            <img src="/images/chrome-store.png" alt="Available in the Chrome Web Store" />
        </a>
        <p>Version</p>
        <p>1.2.0</p>

        <p>Updated</p>
        <p>20th April 2021</p>

        <p>Language</p>
        <p>English (United Kingdom)</p>
    </div>
</Container>
*/