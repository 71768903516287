import axios from 'axios'
import { 
    // request
    FORGOT_REQUEST_SUBMITTED,
    FORGOT_REQUEST_SUCCESS,
    FORGOT_REQUEST_FAIL,
    // check
    FORGOT_CHECK_SUBMITTED,
    FORGOT_CHECK_SUCCESS,
    FORGOT_CHECK_FAIL,
    // update
    FORGOT_UPDATE_SUBMITTED,
    FORGOT_UPDATE_SUCCESS,
    FORGOT_UPDATE_FAIL,
    // delete
    FORGOT_DELETE_SUBMITTED,
    FORGOT_DELETE_SUCCESS,
    FORGOT_DELETE_FAIL
} from './types'

export const startRequest = ({ email }) => async dispatch => {
    try {
        // change the state to submitting
        dispatch({
            type: FORGOT_REQUEST_SUBMITTED
        })

        // request a password reset to the given email
        await axios.post(
            '/api/forgot/request',
            JSON.stringify({ email }),
            { headers: { 'Content-Type': 'application/json' } }
        )

        // email sent successfully
        dispatch({
            type: FORGOT_REQUEST_SUCCESS
        })

    } catch (error) {
        dispatch({
            type: FORGOT_REQUEST_FAIL,
            payload: [...(error.response ? error.response.data.errors : [{ msg: error.message }])]
        })
    }
}

export const startCheck = (token) => async dispatch => {
    try {
        // change the state to submitting
        dispatch({
            type: FORGOT_CHECK_SUBMITTED
        })

        // request a password reset to the given email
        await axios.post(
            '/api/forgot/check',
            JSON.stringify({ token }),
            { headers: { 'Content-Type': 'application/json' } }
        )

        // email sent successfully
        dispatch({
            type: FORGOT_CHECK_SUCCESS
        })

    } catch (error) {
        dispatch({
            type: FORGOT_CHECK_FAIL,
            payload: error.response ? error.response.data : { msg: error.message }
        })
    }
}

export const startUpdate = ({ token, password }) => async dispatch => {
    try {
        // change the state to submitting
        dispatch({
            type: FORGOT_UPDATE_SUBMITTED
        })

        // send the new password with the reset token
        await axios.post(
            '/api/forgot/update',
            JSON.stringify({ token, password }),
            { headers: { 'Content-Type': 'application/json' } }
        )

        // password changed successfully
        dispatch({
            type: FORGOT_UPDATE_SUCCESS
        })

    } catch (error) {
        dispatch({
            type: FORGOT_UPDATE_FAIL,
            payload: [...(error.response ? error.response.data.errors : [{ msg: error.message }])]
        })
    }
}

export const startDelete = (token) => async dispatch => {
    try {
        // change the state to submitting
        dispatch({
            type: FORGOT_DELETE_SUBMITTED
        })

        // send the token to be deleted
        await axios.post(
            '/api/forgot/delete',
            JSON.stringify({ token }),
            { headers: { 'Content-Type': 'application/json' } }
        )

        // reset token deleted
        dispatch({
            type: FORGOT_DELETE_SUCCESS
        })
    } catch (error) {
        dispatch({
            type: FORGOT_DELETE_FAIL,
            payload: error.response ? error.response.data : { msg: error.message }
        })
    }
}