import React, { Fragment } from 'react'
import { Row, Col, Container, Carousel as BootstrapCarousel, ResponsiveEmbed } from 'react-bootstrap'
import { Route, Switch, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChrome } from '@fortawesome/free-brands-svg-icons'
import AppCard from './AppCard'
import NotFound from './NotFound'
import MeetingCaller from './portfolio/MeetingCaller'

export const Browse = () => (
    <Container className="container-page">
        <Row>
            <Col className="col" xs={{span:10, offset:1}} md={{span:6, offset:3}} lg={{span:4, offset:4}}>
                <AppCard 
                    title="Meeting Caller"
                    titleIcon={faChrome}
                    text="Chrome extension providing a Skype-like caller UI for Google Meet."
                    href="/portfolio/meeting-caller" />
            </Col>
        </Row>
    </Container>
)

export const Header = ({ icon, title, children }) => (
    <div className="portfolio-header">
        <Container>
            <h1 className="text-center mb-2"><FontAwesomeIcon icon={icon} className="mr-2" />{title}</h1>
            <nav className="nav nav-pills justify-content-center">
                {children}
            </nav>
        </Container>
    </div>
)

export const HeaderLink = ({ to, exact=false, children}) => (
    <NavLink className="nav-link" to={to} exact={exact}>{children}</NavLink>
)

export const Carousel = ({children}) => (
    <div className="portfolio-carousel">
        <Container className="p-2">
            <BootstrapCarousel>
                {children.map((c, idx) => (
                    <BootstrapCarousel.Item key={idx}>
                        {c}
                    </BootstrapCarousel.Item>
                ))}
            </BootstrapCarousel>
        </Container>
    </div>
)

export const CarouselImg = ({ src, alt }) => (
    <img src={src} alt={alt} />
)

export const OverviewMain = ({children}) => (
    <Col className="overview-main" xs={12} lg={8}>
        <h5>Overview</h5>
        {children}
    </Col>
)

export const OverviewSidebar = ({children}) => (
    <Col className="overview-sidebar" xs={12} lg={4}>
        <h5>Additional Information</h5>
        {children}
    </Col>
)

export const Property = ({ title, children }) => (
    <Fragment>
        <p className="property-title">{title}</p>
        <p>{children}</p>
    </Fragment>
)

export class Overview extends React.Component {
    static Main = OverviewMain;
    static Sidebar = OverviewSidebar;

    render() {
        return (
            <Container className="portfolio-overview">
                <Row>
                    { this.props.children }
                </Row>
            </Container>
        )
    }
}

export class Portfolio extends React.Component {
    static Header = Header;
    static HeaderLink = HeaderLink;
    static Carousel = Carousel;
    static CarouselImg = CarouselImg;
    static Overview = Overview;
    static Property = Property;

    render() {
        return (
            <Switch>
                <Route exact path="/portfolio" component={Browse}/>
                <Route path="/portfolio/meeting-caller" component={MeetingCaller}/>
                <NotFound />
            </Switch>
        )
    }
}

export default Portfolio