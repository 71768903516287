import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Container, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { startLogin } from '../../actions/auth'
import Input from '../Input'
import Button from '../Button'
import CardHeader from '../CardHeader'

export class Login extends React.Component {
    
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.canSubmit = this.canSubmit.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loading && !this.props.loading && this.props.errors) {
            this.props.errors.filter(e => !e.param).forEach(e => toast.error(e.msg))
        }
    }

    handleChange(event) {
        const { name, value } = event.target
        this.setState((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    hasValue(str) {
        return !!str && str.length > 0
    }

    canSubmit() {
        return this.hasValue(this.state.email) && 
            this.hasValue(this.state.password) && 
            !this.props.loading
    }

    handleSubmit(event) {
        event.preventDefault()
        this.props.startLogin(this.state)
    }

    render() {
        return (
            <Container className="container-page content-center">
                <Card className="card-md card-responsive mb-3">
                    <CardHeader icon={ faSignInAlt }>
                        Sign in to your account
                    </CardHeader>
                    <Card.Body>
                        <form onSubmit={this.handleSubmit}>
                            <Input
                                required
                                type="email"
                                placeholder="Email"
                                autoComplete="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                disabled={ this.props.loading ? 'disabled' : '' }
                                error={ this.props.errors && this.props.errors.find(e => e.param === 'email') }
                            />
                            <Input
                                required
                                type="password"
                                placeholder="Password"
                                autoComplete="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                disabled={ this.props.loading ? 'disabled' : '' }
                                error={ this.props.errors && this.props.errors.find(e => e.param === 'password') }
                            />
                            <Button type="submit" block disabled={ !this.canSubmit() } loading={this.props.loading}>
                                Sign In
                            </Button>
                        </form>
                    </Card.Body>
                    <Card.Footer className="text-center">
                        <Link to="/register" className="btn btn-secondary btn-sm">Create New Account</Link>
                    </Card.Footer>
                </Card>
                <Link to="/forgot" className="mb-3">
                    <small>Forgot password?</small>
                </Link>
            </Container>
        )
    }
}

Login.propTypes = {
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.array,
    startLogin: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.auth.login.loading,
    errors: state.auth.login.errors
})

export default connect(mapStateToProps, { startLogin })(Login)